import React, { useEffect, useState } from 'react';
import Header from '../components/Header';

//import { slideOn } from '../components/Animations';
import Cursor from '../components/Cursor';
// import { Helmet } from 'react-helmet';
import Colophon from '../components/Colophon';
import SeoBasic from '../components/SeoBasic';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
import Scroll from '../components/Scroll';
import { StaticImage } from 'gatsby-plugin-image';
import phone from '../images/phone.svg';
// import { getFluidImageObject } from 'gatsby-transformer-cloudinary/api';

gsap.registerPlugin(ScrollTrigger);
// markup
const IndexPage = ({ pageContext, transitionStatus }) => {
  const [phoneImg, setPhoneImg] = useState(undefined);

  // useEffect(() => {
  //   getFluidImageObject({
  //     public_id: 'popland_trans',
  //     cloudName: 'popland',
  //     originalHeight: 98,
  //     originalWidth: 302,
  //     maxWidth: 302,
  //     transformations: ['c_fill', 'f_auto'],
  //   }).then((result) => setPhoneImg(result));
  // }, []);

  useEffect(() => {
    console.log(
      '%c Powered by popLand',
      'font-size: 18px; padding: 70px 125px;'
    );
  }, []);
  useEffect(() => {
    if (transitionStatus) {
      // console.log('animate', transitionStatus);
    }
    if (transitionStatus === 'exiting') {
      gsap.to('.mainpage', {
        delay: 0.1,
        duration: 0.8,
        x: '120%',
        ease: 'power4.inOut',
      });
      gsap.to('.mainbg', {
        delay: 0.1,
        duration: 0.9,
        opacity: '0',
        ease: 'power4.inOut',
      });
      gsap.to('.scrollarrow', {
        delay: 0.1,
        duration: 0.1,
        opacity: '0',
        ease: 'power4.inOut',
      });
    }
    if (transitionStatus === 'entering') {
      gsap.fromTo(
        '.mainpage',
        { opacity: 0 },
        { delay: 0.1, duration: 0.8, opacity: 1 }
      );
      gsap.fromTo(
        '.mainbg',
        { opacity: 0 },
        { delay: 0.1, duration: 0.8, opacity: 1 }
      );
    }
  }, [transitionStatus]);

  //const divh = { height: '200vh' };
  //console.log('qui', pageContext.pagecontent.seo);

  useEffect(() => {
    //gsap.to('.panel', { duration: 0, autoAlpha: 0 });
    //gsap.to('.panel', { duration: 1, delay: 0.5, autoAlpha: 1 });
    // gsap.to('.first-panel', { duration: 2, css: { display: 'none' } });
    // gsap.to(body1, { duration: 0, css: { display: 'block' } });
    // slideOn(body1);

    gsap.fromTo(
      '.mainbg',
      { opacity: 0 },
      { delay: 0.1, duration: 0.8, opacity: 1 }
    );
    gsap.fromTo(
      '.mainpage',
      { opacity: 0 },
      { delay: 0.1, duration: 0.8, opacity: 1 }
    );

    gsap.from('.apptitle', {
      scrollTrigger: {
        trigger: '.apptitle',
        start: 'top bottom',
        scrub: 1.2,
        end: 'bottom center',
      },
      y: -20,
      duration: 1.2,
      ease: 'power3.inOut',
    });

    gsap.from('.appdesc', {
      scrollTrigger: {
        trigger: '.appdesc',
        start: 'top bottom',
        scrub: 1.2,
        end: 'bottom center',
      },
      y: 20,
      duration: 1.2,
      ease: 'power3.inOut',
    });

    gsap.from('#cell', {
      scrollTrigger: {
        trigger: '#cell',
        start: 'top bottom',
        scrub: 1.2,
        end: 'bottom center',
      },
      y: -100,
      duration: 1.2,
      ease: 'power3.inOut',
    });

    gsap.utils.toArray('.panel').forEach((panel, i) => {
      ScrollTrigger.create({
        trigger: panel,
        start: 'top top',
        pin: true,
        pinSpacing: false,
        // snap: 1 / 1, // snap whole page to the closest section!
        // snap: {
        //   snapTo: 1 / 1,
        //   duration: { min: 0.2, max: 0.4 },
        //   delay: 0.1,
        //   ease: 'power1.inOut',
        // },
      });
    });
  }, []);
  // let body1,
  //   body2,
  //   body3,
  //   body4,
  //   body5,
  //   body6 = useRef(null);
  //const mydata = pageContext.pagecontent.seo;
  return (
    <>
      <Cursor />
      <main className="container mx-auto px-2 md:px-0">
        <Header />
      </main>
      <div className="w-full h-screen mainbg bg-curve bg-contain bg-no-repeat opacity-0">
        <div className="w-full h-screen flex flex-wrap justify-center content-between mt-20 mainpage ">
          <div className="flex w-full">
            <div className="apptitle list-none text-gray-800 text-4xl md:text-6xl lg:text-8xl font-extrabold relative uppercase pb-5">
              a
            </div>

            <div className="appdesc px-5 text-xl uppercase">
              Progettazione e sviluppo App native per dispositivi mobili (iOS,
              Android) e Progressive Web App. Soluzioni chiavi in mano dal
              design alla pubblicazione sugli Store; integrazioni con backend,
              sviluppo backend su Google Firebase.
            </div>
          </div>
          <div className="text-center -mt-80 scrollarrow">scr0ll</div>
        </div>
      </div>
      <main className="container mx-auto px-2 md:px-0">
        <SeoBasic slug="home" />

        <div className="w-full h-screen flex justify-center items-center min-h-full panel opacity-0 first-panel mainpage flex-wrap bg-gray-50">
          <div className="flex flex-wrap w-full md:w-9/12">
            <div className="w-full md:w-1/2 lg:w-1/3  text-center">
              <div className="m-2 border border-gray-300 flex-col">
                <div>Sito Web</div>
                <div>
                  Siti personali, di rappresentanza, per aziende, attività
                  commerciali, ristoranti, organizzazioni di qualsiasi
                  dimensione, siti vetrina, di news, magazine.
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3  text-center">
              <div className="m-2 border border-gray-300">
                <div>Blog</div>
                <div>
                  Design personalizzato, con una struttura di agile gestione,
                  consulenza per avvio dell’attività editoriale e di
                  pubblicazione. Sviluppo plugin per WordPress. Design di temi
                  per WordPress.
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3  text-center">
              <div className="m-2 border border-gray-300">
                <div>Ecommerce</div>
                <div>
                  Per la vendita di un numero illimitato di prodotti o servizi
                  di qualunque categoria merceologica, con i più diffusi metodi
                  di pagamento. Sviluppo plugin per WooCommerce. Temi
                  personalizzati per WooCommerce
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3  text-center">
              <div className="m-2 border border-gray-300">
                <div>Eventi</div>
                <div>
                  Ideazione e organizzazione di eventi nei settori turistico,
                  gastronomico, culturale, ufficio stampa, sponsorship.
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3  text-center">
              <div className="m-2 border border-gray-300">
                <div>Social Network</div>
                <div>
                  Consulenza per gestione e ottimizzazione dei social media,
                  Facebook, Twitter, Instagram, Linkedin.
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3 text-center">
              <div className="m-2 border border-gray-300">
                <div>Web Marketing</div>
                <div>
                  Ottimizzazione per i motori di ricerca, indicizzazione,
                  pubblicità su Google, Youtube, Facebook, web analytics.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full h-screen flex justify-center items-center mainpage panel bg-gray-100">
          <div className="flex-col text-center">
            <div className="w-1/3 mx-auto mb-4" id="cell">
              {/* <img src={phone} alt="phone" /> */}
              <StaticImage src="../images/phone.svg" alt="phonepool" />
            </div>
            <div className="apptitle list-none text-gray-800 text-4xl md:text-6xl lg:text-8xl font-extrabold relative overflow-hidden uppercase pb-5">
              SVILUPPPO APP
            </div>

            <div className="appdesc px-5 text-xl uppercase">
              Progettazione e sviluppo App native per dispositivi mobili (iOS,
              Android) e Progressive Web App. Soluzioni chiavi in mano dal
              design alla pubblicazione sugli Store; integrazioni con backend,
              sviluppo backend su Google Firebase.
            </div>
          </div>
        </div>

        <div className="w-full h-screen flex justify-center items-center mainpage panel bg-gray-200 flex-wrap">
          <div className="flex flex-wrap w-9/12">
            <div className="w-full md:w-1/2  text-center">
              <div className="m-2 border border-gray-300 flex-col">
                <div>DESIGN</div>
                <div>
                  Studio grafico per logo e immagine coordinata dei canali di
                  comunicazione online e offline.
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2  text-center">
              <div className="m-2 border border-gray-300 flex-col">
                <div>FOTOGRAFIA</div>
                <div>
                  Servizi fotografici per la ristorazione, eventi, istituzionali
                  per siti web e materiale corporate cartaceo.
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2  text-center">
              <div className="m-2 border border-gray-300 flex-col">
                <div>VIDEO</div>
                <div>
                  Riprese, postproduzione, montaggio, motion graphics,
                  titolazioni, effetti per video di presentazione di ristoranti,
                  chef, eventi.
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2  text-center">
              <div className="m-2 border border-gray-300 flex-col">
                <div>FORMAZIONE</div>
                <div>
                  Attività formativa per startup del settore ristorativo, per la
                  gestione di un sito web, di un blog e dei social media.
                </div>
              </div>
            </div>
          </div>
        </div>

        <Scroll />
        <Colophon />
      </main>
    </>
  );
};

export default IndexPage;
